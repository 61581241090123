import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Grid,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  IconButton,
  Alert,
  Stack,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import axios from 'axios';

const API_ITEM_DETAIL = '/suggestedOrders/itemDetail';
const IMAGE_BASE_URL_SMALL = 'https://media.server.theshootingwarehouse.com/small/';
const IMAGE_BASE_URL_HIRES = 'https://media.server.theshootingwarehouse.com/hires/';

const RowDetailModal = ({ open, handleClose, itemNumber }) => {
  const theme = useTheme();
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  const handleInternalClose = useCallback(() => {
    handleClose();

    setTimeout(() => {
      setProduct(null);
      setError(null);
      setIsLoading(false);
    }, 300);
  }, [handleClose]);

  useEffect(() => {
    if (open && itemNumber) {
      const fetchDetails = async () => {
        setIsLoading(true);
        setError(null);
        setProduct(null);
        try {
          const response = await axios.post(API_ITEM_DETAIL, { itemNumber });

          if (response.data && response.data.length > 0) {
            setProduct(response.data[0]);
          } else {
            setError(`No details found for item ${itemNumber}.`);
          }
        } catch (error) {
          console.error('Failed to fetch product details:', error);
          setError(error.response?.data?.message || 'Failed to load product details.');
        } finally {
          setIsLoading(false);
        }
      };
      fetchDetails();
    }
  }, [itemNumber, open]);

  const handleImageClick = useCallback(() => setIsImageModalOpen(true), []);
  const handleImageModalClose = useCallback(() => setIsImageModalOpen(false), []);

  const renderSpecification = (key, value) => (
    <Grid item xs={12} sm={6} md={4} key={key}>
      {' '}
      <Typography variant='body2' color='text.secondary' sx={{ fontWeight: 'medium' }}>
        {key}:
      </Typography>
      <Typography variant='body2'>{value}</Typography>
    </Grid>
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={handleInternalClose}
        maxWidth='lg'
        fullWidth
        aria-labelledby='product-detail-dialog-title'
      >
        <DialogTitle id='product-detail-dialog-title'>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Typography variant='h6' component='div'>
              Product Details
            </Typography>
            <IconButton
              aria-label='close'
              onClick={handleInternalClose}
              sx={{ color: (theme) => theme.palette.grey[500] }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent dividers>
          {' '}
          {isLoading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '400px',
              }}
            >
              <CircularProgress />
            </Box>
          ) : error ? (
            <Alert severity='error' sx={{ m: 2 }}>
              {error}
            </Alert>
          ) : product ? (
            <Grid container spacing={3}>
              {' '}
              <Grid item xs={12} md={5} sx={{ position: 'relative' }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    minHeight: 300,
                    p: 1,
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: 1,
                  }}
                >
                  <IconButton
                    aria-label='Zoom image'
                    onClick={handleImageClick}
                    sx={{
                      position: 'absolute',
                      top: theme.spacing(1),
                      right: theme.spacing(1),
                      backgroundColor: 'rgba(255, 255, 255, 0.7)',
                      '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
                      zIndex: 1,
                    }}
                  >
                    <ZoomInIcon />
                  </IconButton>
                  <img
                    src={`${IMAGE_BASE_URL_SMALL}${product.itemno}.jpg`}
                    alt={`${product.model || 'Product'} thumbnail`}
                    style={{
                      maxWidth: '100%',
                      maxHeight: '450px',
                      objectFit: 'contain',
                    }}
                    onError={(e) => {
                      e.target.style.display = 'none';
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={7}>
                <Stack spacing={2}>
                  {' '}
                  <Box>
                    <Typography variant='h5' component='h2' gutterBottom>
                      {product.brand_name || 'N/A'} — {product.model || 'N/A'}
                    </Typography>
                    <Typography variant='subtitle1' color='text.secondary'>
                      {product.series ? `${product.series} — ` : ''}{' '}
                      {product.category_group || 'N/A'}
                    </Typography>
                  </Box>
                  <Divider />
                  <Typography variant='h6' component='h3'>
                    Basic Information
                  </Typography>
                  <Grid container spacing={1.5}>
                    {' '}
                    {renderSpecification('Item Number', product.itemno)}
                    {renderSpecification('UPC', product.upc)}
                    {renderSpecification('Mfr SKU', product.mfgino)}
                    {renderSpecification('Department', product.department)}
                    {renderSpecification('Category', product.category)}
                  </Grid>
                  <Divider />
                  <Typography variant='h6' component='h3'>
                    Specifications
                  </Typography>
                  <Grid container spacing={1.5}>
                    {' '}
                    {product.facets && Object.keys(product.facets).length > 0 ? (
                      Object.entries(product.facets).map(([key, value]) =>
                        renderSpecification(key, value)
                      )
                    ) : (
                      <Grid item xs={12}>
                        <Typography variant='body2' color='text.secondary'>
                          No additional specifications available.
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
          ) : (
            <Typography sx={{ p: 3, textAlign: 'center', color: 'text.secondary' }}>
              Product details could not be loaded.
            </Typography>
          )}
        </DialogContent>
      </Dialog>

      {product && (
        <Dialog
          open={isImageModalOpen}
          onClose={handleImageModalClose}
          maxWidth='xl'
          aria-labelledby='image-detail-dialog-title'
        >
          <DialogTitle id='image-detail-dialog-title' sx={{ py: 1, px: 2 }}>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <Typography variant='h6' component='div' sx={{ fontSize: '1.1rem' }}>
                {product.brand_name} - {product.model} (Item: {product.itemno})
              </Typography>
              <IconButton
                aria-label='close image view'
                onClick={handleImageModalClose}
                sx={{ color: (theme) => theme.palette.grey[500] }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent
            sx={{ p: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <img
              src={`${IMAGE_BASE_URL_HIRES}${product.itemno}.png`}
              alt={`${product.model || 'Product'} high-res`}
              style={{ maxWidth: '100%', maxHeight: '80vh', objectFit: 'contain' }}
              onError={(e) => {
                e.target.alt = 'High-res image not available';
              }}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default RowDetailModal;
