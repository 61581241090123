import React, { useState, useEffect, useCallback } from 'react';
import { Box, useTheme } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import axios from 'axios';
import TableRowActions from './Components/TableRowActions';
import getColumns from './Components/TableColumns';
import LoadingSpinner from '../../../_GlobalComponents/LoadingSpinner';
import TableHeaderActions from './Components/TableHeaderActions';
import OrderConfirmModal from '../OrderConfirmModal/OrderConfirmModal';
import RowDetailModal from './Components/RowDetailModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSmartOrderCart,
  addToSmartOrderCart,
  deleteFromSmartOrderCart,
  clearSmartOrderCart,
  updateCartItemQuantity,
} from '../../../../Redux/SmartOrderCartSlice';

const SuggestedOrderTable = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [token, setToken] = useState('');
  const [dataRefreshToggle, setDataRefreshToggle] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItemNumber, setSelectedItemNumber] = useState(null);
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('#000');
  const [pageTitle, setPageTitle] = useState('Smart Ordering');

  const theme = useTheme();
  const dispatch = useDispatch();
  const selectedRows = useSelector((state) => state.smartOrderCart.value);

  useEffect(() => {
    const path = window.location.pathname;
    const pathParts = path.split('/');
    const tokenFromUrl = pathParts[pathParts.length - 1];
    setToken(tokenFromUrl);
    setIsLoading(true);

    axios
      .post('/suggestedOrders', {
        customerNumber: tokenFromUrl,
      })
      .then((response) => {
        const { result, message, message_color, page_title, details } = response.data;

        setMessage(message);
        setMessageColor(message_color || '#000');
        setPageTitle(page_title);

        if (result === 'success') {
          const dataWithCheckbox = details.map((item) => ({
            ...item,
            isChecked: false,
          }));
          setData(dataWithCheckbox);
        } else if (result === 'empty') {
          setData([]);
        } else if (result === 'error') {
          console.error('Error fetching data:', message);
          setData([]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      });
  }, [dataRefreshToggle]);

  const handleCheckChange = (row, isChecked) => {
    const updatedData = data.map((item) =>
      item.item_number === row.original.item_number ? { ...item, isChecked } : item
    );
    setData(updatedData);

    if (isChecked) {
      dispatch(addToSmartOrderCart({ ...row.original, line_source: 'smart_order' }));
    } else {
      dispatch(deleteFromSmartOrderCart(row.original.item_number));
    }
  };

  useEffect(() => {
    if (!isLoading) {
      setData((prevData) =>
        prevData.map((item) => {
          const cartItem = selectedRows.find(
            (cartItem) => cartItem.item_number === item.item_number
          );
          return cartItem
            ? { ...item, isChecked: true, suggested_qty: cartItem.suggested_qty }
            : { ...item, isChecked: false };
        })
      );
    }
  }, [selectedRows, isLoading]);

  const handleButtonClick = () => {
    if (selectedRows.length > 0) {
      setTimeout(() => {
        setIsModalOpen(true);
      }, 0);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleItemOpenModal = (itemNumber) => {
    setSelectedItemNumber(itemNumber);
    setModalOpen(true);
  };

  const handleItemCloseModal = () => {
    setModalOpen(false);
    setSelectedItemNumber(null);
  };

  const handleConfirmOrder = (poNumber, shippingMethod) => {
    const items = selectedRows.map((row) => ({
      item_number: row.item_number,
      quantity: row.suggested_qty,
    }));

    axios
      .post('/suggestedOrders/placeOrder', {
        accountNumber: token,
        poNumber: poNumber,
        shippingMethod: shippingMethod,
        items: items,
      })
      .then((response) => {
        console.log('Order placed successfully');
        setIsModalOpen(false);
       
      })
      .catch((error) => {
        console.error('Error placing order:', error);
      
      });
  };

  
  const handleOrderPlaced = () => {
    dispatch(clearSmartOrderCart());
    setData([]);
    setDataRefreshToggle((prev) => !prev);
  };


 const handleQtyChange = useCallback((item_number, rawNewQty) => {
  const newQuantity = parseInt(rawNewQty, 10); 


  const baseItemData = data.find(item => item.item_number === item_number);
  if (!baseItemData) {
    console.error("Could not find item in local data:", item_number);
    return;
  }

  if (isNaN(newQuantity) || newQuantity < 1) {
  
    const isInCart = selectedRows.some(cartItem => cartItem.item_number === item_number);
    if (isInCart) {
      dispatch(deleteFromSmartOrderCart(item_number));
    }

    setData(currentData => currentData.map(item =>
      item.item_number === item_number
        ? { ...item, suggested_qty: baseItemData.suggested_qty || '' }
        : item
    ));
    return; 
  }



 
  setData(currentData => currentData.map(item =>
    item.item_number === item_number
      ? { ...item, suggested_qty: newQuantity }
      : item
  ));


  const isInCart = selectedRows.some(cartItem => cartItem.item_number === item_number);

  if (isInCart) {
 
    dispatch(updateCartItemQuantity({ item_number: item_number, newQuantity: newQuantity }));
  } else {
  
    dispatch(addToSmartOrderCart({
      ...baseItemData,
      suggested_qty: newQuantity, 
      line_source: 'smart_order', 
     
    }));
  }

}, [dispatch, data, selectedRows]);



  const handleClearSelection = () => {
    const updatedData = data.map((item) => ({ ...item, isChecked: false }));
    setData(updatedData);
    dispatch(clearSmartOrderCart());
  };

  const handleSelectAll = () => {
    const updatedData = data.map((item) => ({
      ...item,
      isChecked: true,
      line_source: 'smart_order',
    }));
    setData(updatedData);
    dispatch(setSmartOrderCart(updatedData));
  };

  const tableColumns = getColumns({ handleItemOpenModal, handleQtyChange });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', flexGrow: 1 }}>
      <LoadingSpinner isLoading={isLoading} />

      <MaterialReactTable
        columns={tableColumns}
        data={data}
        enableHiding={false}
        enableToolbarInternalButtons={false}
        initialState={{
          showGlobalFilter: true,
          showColumnFilters: false,
          density: 'compact',
          pagination: { pageIndex: 0, pageSize: 20 },
          sorting: [{ id: 'last_ordered_days', ascending: true }],
        }}
        positionToolbarAlertBanner='bottom'
        autoResetPageIndex={false}
        enableStickyHeader
        enableRowActions
        isLoading={isLoading}
        renderLoadingOverlay={() => null}
        localization={{
          noRecordsToDisplay: isLoading ? '' : 'No Records To Display',
        }}
        muiTableHeadCellProps={{
          sx: {
            fontSize: '1rem',
            fontWeight: 600,
            backgroundColor: theme.palette.grey[100],
          },
        }}
        renderRowActions={({ row }) => (
          <TableRowActions row={row} onCheckChange={handleCheckChange} />
        )}
        renderToolbarInternalActions={() => <Box />}
        renderTopToolbarCustomActions={({ table }) => (
          <TableHeaderActions
            table={table}
            selectedRows={selectedRows}
            handleButtonClick={handleButtonClick}
            handleClearSelection={handleClearSelection}
            handleSelectAll={handleSelectAll}
            data={data}
            message={message}
            messageColor={messageColor}
          />
        )}
        muiTablePaperProps={{
          sx: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            height: '100%',
          },
        }}
        muiTopToolbarProps={{
          sx: {
            flexShrink: 0,
          },
        }}
        muiTableContainerProps={{
          sx: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflow: 'auto',
          },
        }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            header: '',
            size: 10,
          },
        }}
      />

      {isModalOpen && (
        <OrderConfirmModal
          handleClose={handleCloseModal}
          customerNumber={token}
          handleOrderPlaced={handleOrderPlaced}
        />
      )}
      <RowDetailModal
        open={modalOpen}
        handleClose={handleItemCloseModal}
        itemNumber={selectedItemNumber}
      />
    </Box>
  );
};

export default SuggestedOrderTable;
