import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {
  MaterialReactTable,
  MRT_ToggleGlobalFilterButton as MrtToggleGlobalFilterButton,
} from 'material-react-table';
import { Delete } from '@mui/icons-material';
import axios from 'axios';
import LoadingSpinner from '../../_GlobalComponents/LoadingSpinner';
import AddItemModal from './AddItemModal';
import DeleteItemModal from './DeleteItemModal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import RowDetailModal from './SuggestedOrderTable/Components/RowDetailModal';

const SettingsTable = () => {
  const theme = useTheme();
  const [minMaxData, setMinMaxData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('#000');
  const [pageTitle, setPageTitle] = useState('');
  const [token, setToken] = useState('');
  const [hiddenRows, setHiddenRows] = useState([]);
  const [addItemModalOpen, setAddItemModalOpen] = useState(false);
  const [deleteItemModalOpen, setDeleteItemModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItemNumber, setSelectedItemNumber] = useState(null);

  useEffect(() => {
    const path = window.location.pathname;
    const pathParts = path.split('/');
    const tokenFromUrl = pathParts[pathParts.length - 1];
    setToken(tokenFromUrl);
    setIsLoading(true);

    axios
      .post('/suggestedOrders/getMinMax', {
        customerNumber: tokenFromUrl,
      })
      .then((response) => {
        const { result, message, message_color, page_title } = response.data;

        setMessage(message);
        setMessageColor(message_color || '#000');
        setPageTitle(page_title);

        if (result === 'success') {
          setMinMaxData(response.data.details);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      });
  }, []);

  const buttonStyle = {
    backgroundColor: theme.palette.tsw.tertiary,
    fontSize: '1rem',
    fontWeight: 600,
    whiteSpace: 'pre',
    mr: 2,
  };

  const handleAddUpdateItem = (itemNumber, itemMin, itemMax) => {
    axios
      .post('/suggestedOrders/addUpdateItem', {
        customerNumber: token,
        itemNumber: itemNumber,
        itemMin: itemMin,
        itemMax: itemMax,
      })
      .then((res) => {
        console.log('Success:', res.data);
      })
      .catch((error) => {
        if (error.response) {
          console.error('Error Message:', error.response.data.message);
          console.error('Debug Info:', error.response.data.debug);
        } else {
          console.error('Unexpected Error:', error.message);
        }
      });
  };

  const handleItemOpenModal = (itemNumber) => {
    setSelectedItemNumber(itemNumber);
    setModalOpen(true);
  };

  const handleItemCloseModal = () => {
    setModalOpen(false);
    setSelectedItemNumber(null);
  };

  const RenderNumericField = ({ value, row, field }) => {
    const [inputValue, setInputValue] = useState(value);
    const [error, setError] = useState(false);
    const [status, setStatus] = useState('idle');

    const handleInputChange = async (e) => {
      const newValue = e.target.value;
      setInputValue(newValue);
      if (newValue === '' || isNaN(newValue)) return;

      const parsedValue = parseFloat(newValue);
      const itemNumber = row.original.item_number;
      const itemMin =
        field === 'min_quantity' ? parsedValue : parseFloat(row.original.min_quantity) || 0;
      const itemMax =
        field === 'max_quantity' ? parsedValue : parseFloat(row.original.max_quantity) || 0;

      if (field === 'max_quantity' && itemMin > parsedValue) {
        setError(true);
      } else if (field === 'min_quantity' && parsedValue > itemMax) {
        setError(true);
      } else {
        setError(false);
        setStatus('loading');

        try {
          await handleAddUpdateItem(itemNumber, itemMin, itemMax);
          setStatus('success');
          setTimeout(() => setStatus('idle'), 1000);
        } catch {
          setStatus('error');
        }
      }
    };

    return (
      <Box display='flex' alignItems='center' gap={1}>
        <TextField
          variant='outlined'
          size='small'
          value={inputValue}
          onChange={handleInputChange}
          error={error}
          helperText={error ? 'Invalid value' : ''}
          inputProps={{
            style: { width: '60px', textAlign: 'center' },
            type: 'number',
          }}
        />
        {status === 'loading' && <CircularProgress size={16} />}
        {status === 'success' && <CheckCircleIcon color='success' />}
        {status === 'error' && <ErrorIcon color='error' />}
      </Box>
    );
  };

  const RenderCellValue = ({ value }) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant='body1'>{value}</Typography>
      </Box>
    );
  };

  const columns = [
    {
      header: 'SS Item #',
      accessorKey: 'item_number',
      size: 75,
      Cell: ({ renderedCellValue }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant='body1'
            onClick={() => handleItemOpenModal(renderedCellValue)}
            sx={{ color: '#1976d2', textDecoration: 'underline', cursor: 'pointer' }}
          >
            {renderedCellValue}
          </Typography>
        </Box>
      ),
    },
    {
      header: 'Mfr #',
      accessorKey: 'manufacturer_sku',
      size: 75,
      Cell: ({ renderedCellValue }) => <RenderCellValue value={renderedCellValue} />,
    },
    {
      header: 'UPC #',
      accessorKey: 'upc',
      size: 100,
      Cell: ({ renderedCellValue }) => <RenderCellValue value={renderedCellValue} />,
    },
    {
      header: 'Manufacturer',
      accessorKey: 'manufacturer_name',
      size: 100,
      Cell: ({ renderedCellValue }) => <RenderCellValue value={renderedCellValue} />,
    },
    {
      header: 'Description',
      accessorKey: 'description',
      size: 400,
      Cell: ({ renderedCellValue }) => <RenderCellValue value={renderedCellValue} />,
    },
    {
      header: 'Min Qty',
      accessorKey: 'min_quantity',
      size: 75,
      Cell: ({ renderedCellValue, row }) => (
        <RenderNumericField value={renderedCellValue} row={row} field='min_quantity' />
      ),
    },
    {
      header: 'Max Qty',
      accessorKey: 'max_quantity',
      size: 75,
      Cell: ({ renderedCellValue, row }) => (
        <RenderNumericField value={renderedCellValue} row={row} field='max_quantity' />
      ),
    },
  ];

  const deleteItem = (itemNumber) => {
    setLoading(true);
    axios
      .post('/suggestedOrders/deleteItem', {
        customerNumber: token,
        itemNumber: itemNumber,
      })
      .then((response) => {
        handleDeleteItemModalClose();
        setMinMaxData((prev) => prev.filter((item) => item.item_number !== itemNumber));
        console.log('Delete successful:', response);
      })
      .catch((error) => {
        console.error('Error deleting item:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAddItemModalClose = () => {
    setAddItemModalOpen(false);
  };

  const handleDeleteItemModalClose = () => {
    setDeleteItemModalOpen(false);
    setItemToDelete(null);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', flexGrow: 1 }}>
      <LoadingSpinner isLoading={isLoading} />
      <MaterialReactTable
        columns={columns}
        data={minMaxData}
        enableHiding={false}
        initialState={{
          showGlobalFilter: true,
          showColumnFilters: false,
          density: 'compact',
          pagination: { pageIndex: 0, pageSize: 20 },
        }}
        positionToolbarAlertBanner='bottom'
        enableStickyHeader
        enableRowActions
        isLoading={isLoading}
        renderLoadingOverlay={() => null}
        localization={{
          noRecordsToDisplay: isLoading ? '' : 'No Records To Display',
        }}
        muiTableHeadCellProps={{
          sx: {
            fontSize: '1rem',
            fontWeight: 600,
            backgroundColor: theme.palette.grey[100],
          },
        }}
        renderToolbarInternalActions={() => <Box />}
        renderTopToolbarCustomActions={() => (
          <Box display='flex' flexDirection='column' gap={1}>
            <Box display='flex' alignItems='baseline' flexWrap='wrap' gap={1}>
              <Typography variant='body1' sx={{ color: messageColor, fontSize: '1.1rem' }}>
                {message}
              </Typography>
            </Box>
            <Box display='flex' gap={2}>
              <Button variant='contained' sx={buttonStyle}>
                <Box
                  sx={{ display: 'flex', alignItems: 'center' }}
                  onClick={() => setAddItemModalOpen(true)}
                >
                  <Typography
                    sx={{
                      letterSpacing: '0.05em',
                      fontWeight: 'bold',
                      textTransform: 'none',
                    }}
                  >
                    Add New Item
                  </Typography>
                </Box>
              </Button>
            </Box>
          </Box>
        )}
        renderRowActions={({ row }) => {
          return (
            <Box display='flex' flexDirection='row' justifyContent='flex-start'>
              <Tooltip title='Delete' aria-label='Delete item'>
                <Delete
                  sx={{ color: theme.palette.delete.main, cursor: 'pointer' }}
                  onClick={() => {
                    setItemToDelete(row.original.item_number);
                    setDeleteItemModalOpen(true);
                  }}
                />
              </Tooltip>
            </Box>
          );
        }}
        muiTablePaperProps={{
          sx: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            height: '100%',
            p: 2,
            boxShadow: 3,
            borderRadius: 2,
          },
        }}
        muiTableContainerProps={{
          sx: {
            flexGrow: 1,
            overflow: 'auto',
          },
        }}
        muiTopToolbarProps={{
          sx: {
            flexShrink: 0,
          },
        }}
        muiTableBodyRowProps={({ row }) => ({
          sx: {
            opacity: hiddenRows.includes(row.id) ? 0.5 : 1,
          },
        })}
      />
      <AddItemModal open={addItemModalOpen} onClose={handleAddItemModalClose} token={token} />
      <DeleteItemModal
        open={deleteItemModalOpen}
        onClose={handleDeleteItemModalClose}
        onDelete={() => deleteItem(itemToDelete)}
        loading={loading}
      />
      <RowDetailModal
        open={modalOpen}
        handleClose={handleItemCloseModal}
        itemNumber={selectedItemNumber}
      />
    </Box>
  );
};

export default SettingsTable;
