import { configureStore, combineReducers } from '@reduxjs/toolkit';
import userInformationSlice from './UserInformationSlice';
import userPermissionSlice from './UserPermissionSlice';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import mainDrawerSlice from './MainDrawerSlice';
import dataAuditSlice from './DataAuditSlice';
import brandLandingSlice from './BrandLandingSlice';
import ReturnSlice from './ReturnSlice';
import SmartOrderCartSlice from './SmartOrderCartSlice';

const persistConfig = {
  key: 'whitelist',
  version: 1,
  storage,
  whitelist: ['userInformation'],
};

const reducer = combineReducers({
  // Add Reducers Here
  userInformation: userInformationSlice,
  userPermission: userPermissionSlice,
  mainDrawer: mainDrawerSlice,
  dataAudit: dataAuditSlice,
  brandId: brandLandingSlice,
  returnObject: ReturnSlice,
  smartOrderCart: SmartOrderCartSlice,
});

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };
