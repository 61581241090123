import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
  Box,
  CircularProgress,
  Stack,
  useTheme,
} from '@mui/material';
import { WarningAmber as WarningIcon } from '@mui/icons-material';

const DeleteItemModal = ({ open, onClose, onDelete, loading }) => {
  const theme = useTheme();

  const handleDialogClose = (event, reason) => {
    if (loading && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
      return;
    }
    if (!loading && onClose) {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      aria-labelledby='delete-confirm-dialog-title'
      aria-describedby='delete-confirm-dialog-description'
      maxWidth='xs'
    >
      <DialogTitle
        id='delete-confirm-dialog-title'
        sx={{ textAlign: 'center', pt: 3 }}
      ></DialogTitle>
      <DialogContent sx={{ textAlign: 'center' }}>
        <Stack spacing={2} alignItems='center'>
          <Box sx={{ mb: 1, height: 50, display: 'flex', alignItems: 'center' }}>
            {' '}
            {loading ? (
              <CircularProgress color='error' />
            ) : (
              <WarningIcon color='error' sx={{ fontSize: 50 }} />
            )}
          </Box>

          {!loading && (
            <DialogContentText id='delete-confirm-dialog-description'>
              Are you sure you want to delete this item?
            </DialogContentText>
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 2, justifyContent: 'center', gap: 2 }}>
        {' '}
        <Button variant='outlined' onClick={onClose} disabled={loading} sx={{ flexGrow: 1 }}>
          Cancel
        </Button>
        <Button
          variant='contained'
          color='error'
          onClick={onDelete}
          disabled={loading}
          startIcon={loading ? <CircularProgress size={20} color='inherit' /> : null}
          sx={{ flexGrow: 1 }}
        >
          {loading ? 'Deleting...' : 'Delete'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteItemModal;
