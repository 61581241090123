import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Typography,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Modal,
  TextField,
} from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import axios from 'axios';
import LoadingSpinner from '../../_GlobalComponents/LoadingSpinner';
import RowDetailModal from './SuggestedOrderTable/Components/RowDetailModal';
import { LibraryAdd, AddShoppingCart } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import OrderConfirmModal from './OrderConfirmModal/OrderConfirmModal';
import AddItemPopup from './AddItemPopup';
import { addToSmartOrderCart, deleteFromSmartOrderCart } from '../../../Redux/SmartOrderCartSlice';
import { green } from '@mui/material/colors';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

const SuggestionsView = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [token, setToken] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('#000');
  const [pageTitle, setPageTitle] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('All');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItemNumber, setSelectedItemNumber] = useState(null);
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 20 });
  const [openAddItemModal, setOpenAddItemModal] = useState(false);
  const [selectedItemForAdd, setSelectedItemForAdd] = useState(null);

  const selectedRows = useSelector((state) => state.smartOrderCart.value);

  useEffect(() => {
    const tokenFromUrl = window.location.pathname.split('/').pop();
    setToken(tokenFromUrl);
    setIsLoading(true);

    axios
      .post('/suggestedOrders/getSmartBuys', { customerNumber: tokenFromUrl })
      .then((response) => {
        const { result, message, message_color, page_title, details } = response.data;
        setMessage(message);
        setMessageColor(message_color || '#000');
        setPageTitle(page_title);
        if (result === 'success' && details) {
          setSuggestions(flattenData(details));
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching suggestions:', error);
        setIsLoading(false);
      });
  }, []);

  const flattenData = useCallback((details) => {
    let arr = [];
    Object.keys(details).forEach((category) => {
      const items = details[category];
      items.forEach((item) => {
        arr.push({ ...item, category });
      });
    });
    return arr;
  }, []);

  const departments = useMemo(() => {
    return Array.from(new Set(suggestions.map((item) => item.department))).sort();
  }, [suggestions]);

  const categories = useMemo(() => {
    if (selectedDepartment === '' || selectedDepartment === 'All') {
      return Array.from(new Set(suggestions.map((item) => item.category))).sort();
    } else {
      return Array.from(
        new Set(
          suggestions
            .filter((item) => item.department === selectedDepartment)
            .map((item) => item.category)
        )
      ).sort();
    }
  }, [suggestions, selectedDepartment]);

  const filteredSuggestions = useMemo(() => {
    return suggestions.filter((item) => {
      const departmentMatches =
        selectedDepartment === '' ||
        selectedDepartment === 'All' ||
        item.department === selectedDepartment;
      const categoryMatches =
        selectedCategory === '' || selectedCategory === 'All' || item.category === selectedCategory;
      return departmentMatches && categoryMatches;
    });
  }, [suggestions, selectedDepartment, selectedCategory]);

  // Memoize event handlers
  const handleItemOpenModal = useCallback((itemNumber) => {
    setSelectedItemNumber(itemNumber);
    setModalOpen(true);
  }, []);

  const handleItemCloseModal = useCallback(() => {
    setModalOpen(false);
    setSelectedItemNumber(null);
  }, []);

  const openOrderModal = useCallback(() => {
    if (selectedRows.length > 0) {
      setIsOrderModalOpen(true);
    } else {
      console.warn('No items in cart.');
    }
  }, [selectedRows]);

  const closeOrderModal = useCallback(() => {
    setIsOrderModalOpen(false);
  }, []);

  const handleConfirmOrder = useCallback(
    (poNumber, shippingMethod) => {
      const items = selectedRows.map((row) => ({
        item_number: row.item_number,
        suggested_qty: row.suggested_qty,
      }));
      axios
        .post('/suggestedOrders/placeOrder', {
          customerNumber: token,
          poNumber: poNumber,
          shippingMethod: shippingMethod,
          items: items,
        })
        .then(() => {
          console.log('Order placed successfully');
          closeOrderModal();
        })
        .catch((error) => {
          console.error('Error placing order:', error);
        });
    },
    [selectedRows, token, closeOrderModal]
  );

  const memoizedColumns = useMemo(
    () => [
      {
        header: 'SS Item #',
        accessorKey: 'item_number',
        size: 75,
        Cell: ({ renderedCellValue }) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant='body1'
              onClick={() => handleItemOpenModal(renderedCellValue)}
              sx={{ color: '#1976d2', textDecoration: 'underline', cursor: 'pointer' }}
            >
              {renderedCellValue}
            </Typography>
          </Box>
        ),
      },
      {
        header: 'Description',
        accessorKey: 'description',
        size: 400,
        Cell: ({ renderedCellValue }) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='body1'>{renderedCellValue}</Typography>
          </Box>
        ),
      },
      {
        header: 'Manufacturer',
        accessorKey: 'manufacturer_name',
        size: 100,
        Cell: ({ renderedCellValue }) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='body1'>{renderedCellValue}</Typography>
          </Box>
        ),
      },
      {
        header: 'Mfr #',
        accessorKey: 'manufacturer_sku',
        size: 75,
        Cell: ({ renderedCellValue }) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='body1'>{renderedCellValue}</Typography>
          </Box>
        ),
      },
      {
        header: 'UPC #',
        accessorKey: 'upc',
        size: 100,
        Cell: ({ renderedCellValue }) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='body1'>{renderedCellValue}</Typography>
          </Box>
        ),
      },
      {
        header: 'Category Group',
        accessorKey: 'category',
        size: 100,
        Cell: ({ renderedCellValue }) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='body1'>{renderedCellValue}</Typography>
          </Box>
        ),
      },
      {
        header: 'SS Qty',
        accessorKey: 'quantity_on_hand',
        size: 100,
        Cell: ({ renderedCellValue }) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='body1'>{renderedCellValue}</Typography>
          </Box>
        ),
      },
      {
        header: 'Cost',
        accessorKey: 'cost',
        size: 100,
        Cell: ({ renderedCellValue, row }) => {
          const onSale = row.original.on_sale;
          let textColor = 'inherit';
          let backgroundColor = 'transparent';
          if (onSale) {
            textColor = green[700];
          }
          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor,
                p: 1,
                borderRadius: 1,
                justifyContent: 'space-between',
              }}
            >
              <Typography
                variant='body1'
                sx={{ fontWeight: onSale ? 'bold' : 'normal', color: textColor }}
              >
                ${renderedCellValue}
              </Typography>
              {onSale && (
                <Tooltip title='On Sale!' placement='right'>
                  <LocalOfferIcon sx={{ ml: 1, color: green[700] }} />
                </Tooltip>
              )}
            </Box>
          );
        },
      },
      {
        header: 'Score',
        accessorKey: 'score',
        enableColumnOrdering: false,
        enableHiding: true,
      },
    ],
    [handleItemOpenModal]
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', flexGrow: 1 }}>
      <LoadingSpinner isLoading={isLoading} />
      <MaterialReactTable
        columns={memoizedColumns}
        data={filteredSuggestions}
        enableStickyHeader
        positionToolbarAlertBanner='bottom'
        enableRowActions
        enableHiding={false}
        isLoading={isLoading}
        renderLoadingOverlay={() => null}
        initialState={{
          showColumnFilters: false,
          showGlobalFilter: true,
          density: 'compact',
          sorting: [{ id: 'score', desc: true }],
          columnVisibility: { score: false },
        }}
        state={{ pagination }}
        onPaginationChange={setPagination}
        localization={{
          noRecordsToDisplay: isLoading ? '' : 'No Suggestions Found',
        }}
        muiTableHeadCellProps={{
          sx: { fontSize: '1rem', fontWeight: 600, backgroundColor: theme.palette.grey[100] },
        }}
        muiTablePaperProps={{
          sx: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            height: '100%',
            p: 2,
            boxShadow: 3,
            borderRadius: 2,
          },
        }}
        muiTableContainerProps={{
          sx: {
            flexGrow: 1,
            overflow: 'auto',
          },
        }}
        muiTopToolbarProps={{
          sx: {
            flexShrink: 0,
          },
        }}
        renderToolbarInternalActions={() => <Box />}
        renderTopToolbarCustomActions={() => (
          <Box display='flex' flexDirection='column' gap={1}>
            <Box display='flex' alignItems='baseline' flexWrap='wrap' gap={1}>
              <Typography variant='body1' sx={{ color: messageColor, fontSize: '1.1rem' }}>
                {message}
              </Typography>
              {pageTitle && (
                <Typography variant='h6' sx={{ fontWeight: 600 }}>
                  {pageTitle}
                </Typography>
              )}
            </Box>
            <Box display='flex' gap={2} flexWrap='wrap'>
              <FormControl sx={{ minWidth: 200 }}>
                <InputLabel id='department-select-label'>Department</InputLabel>
                <Select
                  labelId='department-select-label'
                  value={selectedDepartment}
                  label='Department'
                  onChange={(e) => {
                    setSelectedDepartment(e.target.value);
                    setSelectedCategory('All');
                  }}
                >
                  <MenuItem value='All'>
                    <em>All</em>
                  </MenuItem>
                  {departments.map((dept) => (
                    <MenuItem key={dept} value={dept}>
                      {dept}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 200 }}>
                <InputLabel id='category-select-label'>Category Group</InputLabel>
                <Select
                  labelId='category-select-label'
                  value={selectedCategory}
                  label='Category Group'
                  onChange={(e) => setSelectedCategory(e.target.value)}
                >
                  <MenuItem value='All'>
                    <em>All</em>
                  </MenuItem>
                  {categories.map((cat) => (
                    <MenuItem key={cat} value={cat}>
                      {cat.charAt(0) + cat.slice(1).toLowerCase()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        )}
        renderRowActions={({ row }) => {
          const item = row.original;
          const cartItem = selectedRows.find(
            (cartItem) => cartItem.item_number === item.item_number
          );
          return (
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='flex-start'
              gap={2}
              alignItems='center'
            >
              <Tooltip title='Add Item To Min/Max List' aria-label='Add Item to Min/Max List'>
                <LibraryAdd
                  sx={{ color: theme.palette.tsw.tertiary, cursor: 'pointer' }}
                  onClick={() => {
                    setSelectedItemForAdd(item.item_number);
                    setOpenAddItemModal(true);
                  }}
                />
              </Tooltip>
              {cartItem ? (
                <TextField
                  variant='outlined'
                  size='small'
                  value={cartItem.suggested_qty}
                  onChange={(e) => {
                    const newQty = e.target.value;
                    if (newQty === '' || Number(newQty) === 0) {
                      dispatch(deleteFromSmartOrderCart(item.item_number));
                    } else {
                      dispatch(deleteFromSmartOrderCart(item.item_number));
                      dispatch(
                        addToSmartOrderCart({
                          ...item,
                          suggested_qty: Number(newQty),
                          line_source: 'smart_buy',
                        })
                      );
                    }
                  }}
                  inputProps={{
                    style: { width: '60px', textAlign: 'center' },
                    type: 'number',
                  }}
                />
              ) : (
                <Tooltip title='Add Item to Cart' aria-label='Add Item to Cart' placement='right'>
                  <AddShoppingCart
                    sx={{ color: theme.palette.tsw.tertiary, cursor: 'pointer' }}
                    onClick={() =>
                      dispatch(
                        addToSmartOrderCart({ ...item, suggested_qty: 1, line_source: 'smart_buy' })
                      )
                    }
                  />
                </Tooltip>
              )}
            </Box>
          );
        }}
      />
      <RowDetailModal
        open={modalOpen}
        handleClose={handleItemCloseModal}
        itemNumber={selectedItemNumber}
      />
      <Modal open={isOrderModalOpen} onClose={closeOrderModal}>
        <OrderConfirmModal
          handleClose={closeOrderModal}
          handleConfirm={handleConfirmOrder}
          customerNumber={token}
        />
      </Modal>
      <AddItemPopup
        open={openAddItemModal}
        onClose={() => setOpenAddItemModal(false)}
        token={token}
        selectedItem={selectedItemForAdd}
      />
    </Box>
  );
};

export default SuggestionsView;
