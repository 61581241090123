import React from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';

const TableHeaderActions = ({
  table,
  handleButtonClick,
  selectedRows,
  handleClearSelection,
  handleSelectAll,
  data,
  message,
  messageColor,
}) => {
  const theme = useTheme();

  const baseButtonStyle = {
    fontSize: '1rem',
    fontWeight: 600,
    whiteSpace: 'pre',
    mr: 2,
  };

  const selectAllButtonStyle = {
    ...baseButtonStyle,
    backgroundColor: theme.palette.tsw.tertiary,
    '&:hover': {
      backgroundColor: theme.palette.tsw.tertiary[700],
    },
  };

  const clearSelectionButtonStyle = {
    ...baseButtonStyle,
    backgroundColor: theme.palette.tsw.secondary,
    '&:hover': {
      backgroundColor: theme.palette.tsw.secondary[700],
    },
  };

  return (
    <Box display='flex' flexDirection='column' gap={1}>
      <Box display='flex' alignItems='baseline' flexWrap='wrap' gap={1}>
        <Typography variant='body1' sx={{ color: messageColor, fontSize: '1.1rem' }}>
          {message}
        </Typography>
      </Box>

      <Box display='flex' gap={2}>
        {data.length > 0 && (
          <>
            {selectedRows.length > 0 ? (
              <Button
                variant='contained'
                sx={clearSelectionButtonStyle}
                onClick={handleClearSelection}
              >
                <Typography
                  sx={{
                    letterSpacing: '0.05em',
                    fontWeight: 'bold',
                    textTransform: 'none',
                  }}
                >
                  Clear Selection
                </Typography>
              </Button>
            ) : (
              <Button variant='contained' sx={selectAllButtonStyle} onClick={handleSelectAll}>
                <Typography
                  sx={{
                    letterSpacing: '0.05em',
                    fontWeight: 'bold',
                    textTransform: 'none',
                  }}
                >
                  Select All
                </Typography>
              </Button>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default TableHeaderActions;
