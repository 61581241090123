const primaryColor = '#00325A';
const secondaryColor = '#878472';
const accentColor = '#DAA520';
const saveColor = '#2E7D32';
const deleteColor = '#CB351A';
const warningColor = '#ffa726';
const infoColor = '#29b6f6';

export const MainTheme = {
  palette: {
    mode: 'light',
    primary: {
      main: '#01315b',
      mainScreen: '#f6fafe', //'#e6f2fc',
      lightScreen: '#e1eaf1',
    },
    secondary: {
      main: '#f44902',
    },
    background: {
      default: '#fbfbfb',
    },
    text: {
      primary: 'rgba(35,34,34,0.87)',
    },
    tsw: {
      primary: '#00325a',
      secondary: '#cb5935',
      tertiary: '#0469A8',
    },
    delete: {
      main: deleteColor,
      contrastText: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Play',
  },
  deminsions: {
    maxWidth: '1600px',
  },
  elements: {
    borderColor: '#e6f2fc',
  },
};
