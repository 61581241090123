import React, { useState, useMemo, useCallback } from 'react';
import {
  Box,
  Divider,
  Tab,
  Tabs,
  Button,
  Tooltip,
  IconButton,
  Modal,
  AppBar,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import { useSelector } from 'react-redux';
import OrderConfirmModal from './OrderConfirmModal/OrderConfirmModal';
import HelpModal from './HelpModal';

const TabBar = ({
  view,
  handleViewChange,
  customerNumber,

  handleOrderPlaced,
}) => {
  const theme = useTheme();
  const cartItems = useSelector((state) => state.smartOrderCart.value);

  const totalItems = useMemo(() => {
    return cartItems.reduce((sum, item) => sum + (Number(item.suggested_qty) || 0), 0);
  }, [cartItems]);

  const totalCost = useMemo(() => {
    return cartItems.reduce(
      (sum, item) => sum + (Number(item.cost) || 0) * (Number(item.suggested_qty) || 0),
      0
    );
  }, [cartItems]);

  const formattedTotalCost = useMemo(() => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(totalCost);
  }, [totalCost]);

  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  const openOrderModal = useCallback(() => {
    if (totalItems > 0) {
      setIsOrderModalOpen(true);
    } else {
      console.warn('Checkout button clicked with no items in cart.');
    }
  }, [totalItems]);

  const closeOrderModal = useCallback(() => {
    setIsOrderModalOpen(false);
  }, []);

  const openHelpModal = useCallback(() => {
    setIsHelpModalOpen(true);
  }, []);

  const closeHelpModal = useCallback(() => {
    setIsHelpModalOpen(false);
  }, []);

  const onOrderPlaced = useCallback(() => {
    closeOrderModal();
    handleOrderPlaced();
  }, [handleOrderPlaced, closeOrderModal]);

  return (
    <>
      <AppBar
        position='sticky'
        color='default'
        elevation={1}
        sx={{ backgroundColor: theme.palette.background.paper }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          {' '}
          <Box>
            <Tooltip title='Help & FAQs' placement='bottom-start'>
              <IconButton color='inherit' onClick={openHelpModal} aria-label='Help and FAQs'>
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
            <Tabs
              value={view}
              onChange={(e, value) => handleViewChange(value)}
              variant='scrollable'
              scrollButtons
              allowScrollButtonsMobile
              aria-label='Main navigation'
              sx={{
                '& .MuiTab-root': {
                  minWidth: 120,
                  fontWeight: theme.typography.fontWeightMedium,
                  fontSize: '1.5rem',
                },
              }}
            >
              <Tab label='Smart Order' value='card' />
              <Tab label='Smart Buys' value='suggestions' />
              <Tab label='Manage Min/Max' value='settings' />
            </Tabs>
          </Box>
          <Box sx={{ whiteSpace: 'nowrap' }}>
            {' '}
            <Tooltip
              title={totalItems > 0 ? 'Proceed to Checkout' : 'Add items to cart to checkout'}
            >
              <span>
                <Button
                  variant='contained'
                  color='primary'
                  startIcon={<ShoppingCartCheckoutIcon />}
                  onClick={openOrderModal}
                  disabled={totalItems === 0}
                  aria-label={`Checkout ${totalItems} items, total cost ${formattedTotalCost}`}
                >
                  {`Checkout (${totalItems}) ${formattedTotalCost}`}
                </Button>
              </span>
            </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>

      {isOrderModalOpen && (
        <OrderConfirmModal
          handleClose={closeOrderModal}
          customerNumber={customerNumber}
          handleOrderPlaced={onOrderPlaced}
        />
      )}

      {isHelpModalOpen && <HelpModal open={isHelpModalOpen} onClose={closeHelpModal} />}
    </>
  );
};

export default TabBar;
