import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  CircularProgress,
  Box,
  Button,
  IconButton,
  Collapse,
  Alert,
  Stack,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';

const API_SEARCH_PRODUCTS = '/suggestedOrders/getAddProductResults';
const API_ADD_UPDATE_ITEM = '/suggestedOrders/addUpdateItem';

const SEARCH_DEBOUNCE_DELAY = 500; // milliseconds

const AddItemModal = ({ open, onClose, token }) => {
  const theme = useTheme();

  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchError, setSearchError] = useState(null);

  const [selectedItem, setSelectedItem] = useState(null);
  const [minQty, setMinQty] = useState('');
  const [maxQty, setMaxQty] = useState('');
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [addError, setAddError] = useState(null);
  const [validationError, setValidationError] = useState('');

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, SEARCH_DEBOUNCE_DELAY);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    const fetchSearchResults = async () => {
      const trimmedSearchTerm = debouncedSearchTerm.trim();

      if (!trimmedSearchTerm) {
        setSearchResults([]);
        setSearchError(null);
        return;
      }

      setLoadingSearch(true);
      setSearchError(null);
      setSelectedItem(null);
      setAddError(null);

      try {
        const response = await axios.post(API_SEARCH_PRODUCTS, {
          searchTerm: trimmedSearchTerm,
        });
        setSearchResults(response.data || []);
      } catch (err) {
        console.error('Search error:', err);
        setSearchError(err.response?.data?.message || 'Failed to fetch results. Please try again.');
        setSearchResults([]);
      } finally {
        setLoadingSearch(false);
      }
    };

    fetchSearchResults();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const min = parseInt(minQty, 10);
    const max = parseInt(maxQty, 10);

    if (!isNaN(min) && !isNaN(max) && max < min) {
      setValidationError('Max Qty cannot be less than Min Qty.');
    } else {
      setValidationError('');
    }
  }, [minQty, maxQty]);

  const isAddButtonDisabled = useMemo(() => {
    const min = parseInt(minQty, 10);
    const max = parseInt(maxQty, 10);
    return (
      loadingAdd ||
      !minQty ||
      !maxQty ||
      isNaN(min) ||
      isNaN(max) ||
      min < 0 ||
      max < 0 ||
      max < min
    );
  }, [minQty, maxQty, loadingAdd]);

  const handleSelectItem = useCallback((item) => {
    setSelectedItem(item);
    setMinQty('');
    setMaxQty('');
    setAddError(null);
    setValidationError('');
  }, []);

  const handleAddItem = useCallback(async () => {
    if (!selectedItem || isAddButtonDisabled) return;

    setLoadingAdd(true);
    setAddError(null);

    try {
      await axios.post(API_ADD_UPDATE_ITEM, {
        customerNumber: token,
        itemNumber: selectedItem.ss_item,
        itemMin: parseInt(minQty, 10),
        itemMax: parseInt(maxQty, 10),
      });

      setSelectedItem(null);
      setMinQty('');
      setMaxQty('');
    } catch (err) {
      console.error('Add item error:', err);
      setAddError(err.response?.data?.message || 'Failed to add item. Please try again.');
    } finally {
      setLoadingAdd(false);
    }
  }, [selectedItem, token, minQty, maxQty, isAddButtonDisabled]);
  const handleInternalClose = useCallback(() => {
    onClose();

    setTimeout(() => {
      setSearchTerm('');

      setSearchResults([]);
      setLoadingSearch(false);
      setLoadingAdd(false);
      setSearchError(null);
      setAddError(null);
      setSelectedItem(null);
      setMinQty('');
      setMaxQty('');
      setValidationError('');
    }, 300);
  }, [onClose]);

  return (
    <Dialog
      open={open}
      onClose={handleInternalClose}
      maxWidth='md'
      fullWidth
      PaperProps={{ sx: { height: '85vh' } }}
      aria-labelledby='add-item-dialog-title'
    >
      <DialogTitle id='add-item-dialog-title'>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Typography variant='h6' component='div'>
            Add Item to Min/Max List
          </Typography>
          <IconButton
            aria-label='close'
            onClick={handleInternalClose}
            sx={{ color: (theme) => theme.palette.grey[500] }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        {' '}
        <Stack spacing={2}>
          <TextField
            fullWidth
            variant='outlined'
            placeholder='Search by Item #, Description, Mfr SKU, UPC, Brand...'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            autoFocus
          />

          {loadingSearch && (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
              <CircularProgress />
            </Box>
          )}
          {searchError && <Alert severity='error'>{searchError}</Alert>}

          <Box
            sx={{
              flexGrow: 1,
              overflowY: 'auto',
              maxHeight: 'calc(85vh - 200px)',
            }}
          >
            {!loadingSearch && searchResults.length > 0 && (
              <List disablePadding>
                {searchResults.map((result) => (
                  <React.Fragment key={result.ss_item}>
                    {' '}
                    <ListItemButton
                      selected={selectedItem?.ss_item === result.ss_item}
                      onClick={() => handleSelectItem(result)}
                      sx={{ pt: 1.5, pb: selectedItem?.ss_item === result.ss_item ? 0.5 : 1.5 }}
                    >
                      <ListItemText
                        primary={
                          <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                            {`Item: ${result.ss_item} | Mfr: ${result.mfr_sku} | UPC: ${result.upc} | ${result.brand}`}
                          </Typography>
                        }
                        secondary={result.description}
                        primaryTypographyProps={{ noWrap: true }}
                        secondaryTypographyProps={{ noWrap: false }}
                      />
                    </ListItemButton>
                    <Collapse
                      in={selectedItem?.ss_item === result.ss_item}
                      timeout='auto'
                      unmountOnExit
                    >
                      <Box
                        sx={{
                          p: 2,
                          pl: 4,
                          display: 'flex',
                          flexDirection: { xs: 'column', sm: 'row' },
                          gap: 1.5,
                          alignItems: { sm: 'center' },
                          borderTop: `1px solid ${theme.palette.divider}`,
                        }}
                      >
                        <TextField
                          label='Min Qty'
                          type='number'
                          value={minQty}
                          onChange={(e) => setMinQty(e.target.value)}
                          size='small'
                          required
                          error={
                            !!validationError ||
                            (!isNaN(parseInt(minQty, 10)) && parseInt(minQty, 10) < 0)
                          }
                          helperText={
                            !isNaN(parseInt(minQty, 10)) && parseInt(minQty, 10) < 0
                              ? 'Min > 0'
                              : ''
                          }
                          inputProps={{ min: 0 }}
                          sx={{ width: { xs: '100%', sm: 100 } }}
                          disabled={loadingAdd}
                        />
                        <TextField
                          label='Max Qty'
                          type='number'
                          value={maxQty}
                          onChange={(e) => setMaxQty(e.target.value)}
                          size='small'
                          required
                          error={
                            !!validationError ||
                            (!isNaN(parseInt(maxQty, 10)) && parseInt(maxQty, 10) < 0)
                          }
                          helperText={
                            validationError ||
                            (!isNaN(parseInt(maxQty, 10)) && parseInt(maxQty, 10) < 0
                              ? 'Max > 0'
                              : '')
                          }
                          inputProps={{ min: 0 }}
                          sx={{ width: { xs: '100%', sm: 100 } }}
                          disabled={loadingAdd}
                        />
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={handleAddItem}
                          disabled={isAddButtonDisabled}
                          startIcon={
                            loadingAdd ? <CircularProgress size={20} color='inherit' /> : null
                          }
                          sx={{ flexShrink: 0, height: 40, width: { xs: '100%', sm: 'auto' } }}
                        >
                          {loadingAdd ? 'Adding...' : 'Add Item'}
                        </Button>

                        {addError && selectedItem?.ss_item === result.ss_item && (
                          <Alert
                            severity='error'
                            sx={{ width: '100%', mt: { xs: 1, sm: 0 }, ml: { sm: 1 } }}
                          >
                            {addError}
                          </Alert>
                        )}
                      </Box>
                    </Collapse>
                  </React.Fragment>
                ))}
              </List>
            )}

            {!loadingSearch &&
              !searchError &&
              debouncedSearchTerm.trim() &&
              searchResults.length === 0 && (
                <Typography sx={{ textAlign: 'center', p: 3, color: 'text.secondary' }}>
                  No results found for "{debouncedSearchTerm}".
                </Typography>
              )}

            {!loadingSearch && !debouncedSearchTerm.trim() && (
              <Typography sx={{ textAlign: 'center', p: 3, color: 'text.secondary' }}>
                Enter a search term to find products.
              </Typography>
            )}
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default AddItemModal;
