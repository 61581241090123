import { Box } from '@mui/material';
import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { clearSmartOrderCart } from '../../Redux/SmartOrderCartSlice';
import SettingsView from './Components/SettingsView';
import SuggestedOrderTable from './Components/SuggestedOrderTable/SuggestedOrderTable';
import SuggestionsView from './Components/SuggestionsView';
import TabBar from './Components/TabBar';

const SmartOrders = () => {
  const [view, setView] = useState('card');
  const [token, setToken] = useState('');
  const dispatch = useDispatch();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    const path = window.location.pathname;
    const pathParts = path.split('/');
    const tokenFromUrl = pathParts[pathParts.length - 1];
    setToken(tokenFromUrl);
  }, []);

  const handleViewChange = (newView) => {
    setView(newView);
  };

  const cleanupAfterOrder = useCallback(() => {
    console.log('SmartOrders: Clearing cart and handling post-order actions.');
    dispatch(clearSmartOrderCart());

    setSnackbarMessage('Order placed successfully!');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
  }, [dispatch]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ flexShrink: 0 }}>
        <TabBar
          view={view}
          handleViewChange={handleViewChange}
          handleOrderPlaced={cleanupAfterOrder}
          customerNumber={token}
        />
      </Box>
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        {view === 'card' && (
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
            <SuggestedOrderTable />
          </Box>
        )}
        {view === 'settings' && (
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
            <SettingsView />
          </Box>
        )}
        {view === 'suggestions' && (
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
            <SuggestionsView />
          </Box>
        )}
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SmartOrders;
