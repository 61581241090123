import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Box,
  Button,
  IconButton,
  CircularProgress,
  Alert,
  Stack,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';

const API_DEFAULT_MIN_MAX = '/suggestedOrders/defaultMinMax';
const API_ADD_UPDATE_ITEM = '/suggestedOrders/addUpdateItem';

const AddItemPopup = ({ open, onClose, token, selectedItem, onItemAdded }) => {
  const theme = useTheme();

  const [minQty, setMinQty] = useState('');
  const [maxQty, setMaxQty] = useState('');
  const [loadingDefaults, setLoadingDefaults] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [error, setError] = useState(null);
  const [validationError, setValidationError] = useState('');

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    setMinQty('');
    setMaxQty('');
    setError(null);
    setValidationError('');

    if (open && selectedItem) {
      const fetchDefaults = async () => {
        setLoadingDefaults(true);
        try {
          const response = await axios.post(API_DEFAULT_MIN_MAX, { productCode: selectedItem });

          if (response.data && response.data.length > 0) {
            const defaultValues = response.data[0];

            setMinQty(String(defaultValues.min || ''));
            setMaxQty(String(defaultValues.max || ''));
          } else {
            console.warn(`No default Min/Max found for item: ${selectedItem}`);
          }
        } catch (err) {
          console.error('Error fetching default min/max:', err);
          setError('Could not load default values.');
        } finally {
          setLoadingDefaults(false);
        }
      };
      fetchDefaults();
    }
  }, [open, selectedItem]);

  useEffect(() => {
    const min = parseInt(minQty, 10);
    const max = parseInt(maxQty, 10);

    if (!isNaN(min) && !isNaN(max) && max < min) {
      setValidationError('Max Qty cannot be less than Min Qty.');
    } else {
      setValidationError('');
    }
  }, [minQty, maxQty]);

  const isConfirmDisabled = useMemo(() => {
    const min = parseInt(minQty, 10);
    const max = parseInt(maxQty, 10);
    return (
      loadingSubmit ||
      loadingDefaults ||
      !minQty ||
      !maxQty ||
      isNaN(min) ||
      isNaN(max) ||
      min < 0 ||
      max < 0 ||
      max < min
    );
  }, [minQty, maxQty, loadingSubmit, loadingDefaults]);

  const handleConfirm = useCallback(async () => {
    if (isConfirmDisabled) return;

    setLoadingSubmit(true);
    setError(null);

    try {
      await axios.post(API_ADD_UPDATE_ITEM, {
        customerNumber: token,
        itemNumber: selectedItem,
        itemMin: parseInt(minQty, 10),
        itemMax: parseInt(maxQty, 10),
      });

      if (onItemAdded) {
        onItemAdded();
      }
      handleClose();
    } catch (err) {
      console.error('Error adding/updating item:', err);
      setError(err.response?.data?.message || 'Failed to save Min/Max. Please try again.');
    } finally {
      setLoadingSubmit(false);
    }
  }, [token, selectedItem, minQty, maxQty, onItemAdded, handleClose, isConfirmDisabled]);

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='add-item-dialog-title'>
      <DialogTitle id='add-item-dialog-title'>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Typography variant='h6' component='div'>
            Set Min/Max For Item #{selectedItem}
          </Typography>
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{ color: (theme) => theme.palette.grey[500] }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        {' '}
        <Stack spacing={2}>
          {' '}
          <Typography variant='body2' color='text.secondary'>
            We've preloaded conservative recommendations based on this product's category.
          </Typography>
          {error && (
            <Alert severity='error' sx={{ mt: 1 }}>
              {error}
            </Alert>
          )}
          {loadingDefaults && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
                my: 2,
              }}
            >
              <CircularProgress size={20} />
              <Typography variant='body2' color='text.secondary'>
                Loading defaults...
              </Typography>
            </Box>
          )}
          {!loadingDefaults && (
            <Stack direction='row' spacing={2} alignItems='flex-start'>
              {' '}
              <TextField
                label='Min Qty'
                type='number'
                value={minQty}
                onChange={(e) => setMinQty(e.target.value)}
                size='small'
                fullWidth
                required
                error={
                  !!validationError || (!isNaN(parseInt(minQty, 10)) && parseInt(minQty, 10) < 0)
                }
                helperText={
                  !isNaN(parseInt(minQty, 10)) && parseInt(minQty, 10) < 0
                    ? 'Cannot be negative'
                    : ''
                }
                inputProps={{ min: 0 }}
              />
              <TextField
                label='Max Qty'
                type='number'
                value={maxQty}
                onChange={(e) => setMaxQty(e.target.value)}
                size='small'
                fullWidth
                required
                error={
                  !!validationError || (!isNaN(parseInt(maxQty, 10)) && parseInt(maxQty, 10) < 0)
                }
                helperText={
                  validationError ||
                  (!isNaN(parseInt(maxQty, 10)) && parseInt(maxQty, 10) < 0
                    ? 'Cannot be negative'
                    : '')
                }
                inputProps={{ min: 0 }}
              />
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        {' '}
        <Button onClick={handleClose} color='secondary'>
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={handleConfirm}
          disabled={isConfirmDisabled}
          startIcon={loadingSubmit ? <CircularProgress size={20} color='inherit' /> : null}
        >
          {loadingSubmit ? 'Saving...' : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddItemPopup;
