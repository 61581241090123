import { createSlice } from '@reduxjs/toolkit';

export const SmartOrderCartSlice = createSlice({
  name: 'smartOrderCart',
  initialState: {
    value: [],
  },
  reducers: {
    setSmartOrderCart: (state, action) => {
      state.value = action.payload;
    },
    addToSmartOrderCart: (state, action) => {
      const existingIndex = state.value.findIndex(
        (item) => item.item_number === action.payload.item_number
      );
      if (existingIndex === -1) {
        state.value.push(action.payload);
      } else {
        console.warn(`Item ${action.payload.item_number} already in cart.`);
      }
    },
    deleteFromSmartOrderCart: (state, action) => {
      state.value = state.value.filter((item) => item.item_number !== action.payload);
    },
    clearSmartOrderCart: (state) => {
      state.value = [];
    },

    updateCartItemQuantity: (state, action) => {
      const { item_number, newQuantity } = action.payload;
      const itemIndex = state.value.findIndex((item) => item.item_number === item_number);

      if (itemIndex !== -1) {
        const quantity = Number(newQuantity);
        if (!isNaN(quantity) && quantity >= 1) {
          state.value[itemIndex].suggested_qty = quantity;
        } else {
          console.warn(`Invalid quantity (${newQuantity}) for item ${item_number}. Removing item.`);
          state.value.splice(itemIndex, 1);
        }
      } else {
        console.warn(`Item ${item_number} not found in cart for update.`);
      }
    },
  },
});

export const {
  setSmartOrderCart,
  addToSmartOrderCart,
  deleteFromSmartOrderCart,
  clearSmartOrderCart,
  updateCartItemQuantity,
} = SmartOrderCartSlice.actions;
export default SmartOrderCartSlice.reducer;
