import React, { useEffect, useState, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  Box,
  Typography,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Button,
  Alert,
  Stack,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';

const HelpModal = ({ open, onClose }) => {
  const theme = useTheme();
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (open) {
      const fetchFaqs = async () => {
        setLoading(true);
        setError(null);
        try {
          const response = await axios.get('/suggestedOrders/faqs');

          const faqData = Array.isArray(response.data) ? response.data : response.data?.faqs || [];
          setFaqs(faqData);
        } catch (err) {
          console.error('Error fetching FAQs:', err);
          setError(
            err.response?.data?.message ||
              'Failed to load Frequently Asked Questions. Please try again later.'
          );
        } finally {
          setLoading(false);
        }
      };

      fetchFaqs();
    } else {
      setFaqs([]);
      setLoading(false);
      setError(null);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='md'
      fullWidth
      PaperProps={{
        sx: {
          maxHeight: '85vh',
        },
      }}
      aria-labelledby='help-dialog-title'
    >
      <DialogTitle
        id='help-dialog-title'
        sx={{
          m: 0,
          px: 2,
          py: 1.5,
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Typography
            variant='h6'
            component='div'
            sx={{
              fontWeight: 600,
              color: theme.palette.tsw?.primary || theme.palette.primary.main,
            }}
          >
            Frequently Asked Questions
          </Typography>
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
              '&:hover': {
                backgroundColor: (theme) => theme.palette.action.hover,
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers sx={{ p: 0 }}>
        {' '}
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: theme.spacing(4),
            }}
          >
            <CircularProgress />
            <Typography sx={{ ml: 2 }}>Loading FAQs...</Typography>
          </Box>
        ) : error ? (
          <Alert severity='error' sx={{ m: theme.spacing(2) }}>
            {error}
          </Alert>
        ) : (
          <Stack spacing={0} sx={{ p: theme.spacing(2) }}>
            {' '}
            {faqs.length === 0 ? (
              <Typography
                sx={{ textAlign: 'center', p: theme.spacing(3), color: 'text.secondary' }}
              >
                No frequently asked questions are currently available.
              </Typography>
            ) : (
              faqs.map((faq, index) => (
                <Accordion
                  key={faq.faq_id || index}
                  disableGutters
                  elevation={0}
                  sx={{
                    border: `1px solid ${theme.palette.divider}`,
                    '&:not(:last-child)': {
                      borderBottom: 0,
                    },
                    '&:first-of-type': {
                      borderTopLeftRadius: theme.shape.borderRadius,
                      borderTopRightRadius: theme.shape.borderRadius,
                    },
                    '&:last-of-type': {
                      borderBottomLeftRadius: theme.shape.borderRadius,
                      borderBottomRightRadius: theme.shape.borderRadius,
                    },
                    '&:before': { display: 'none' },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.common.white }} />}
                    aria-controls={`faq-content-${index}`}
                    id={`faq-header-${index}`}
                    sx={{
                      backgroundColor: theme.palette.tsw?.primary || theme.palette.primary.main,
                      color: theme.palette.common.white,
                      minHeight: 48,
                      '&.Mui-expanded': {
                        minHeight: 48,
                      },
                      '& .MuiAccordionSummary-content': {
                        margin: theme.spacing(1, 0),
                      },

                      borderTopLeftRadius: 'inherit',
                      borderTopRightRadius: 'inherit',
                    }}
                  >
                    <Typography variant='subtitle1' sx={{ fontWeight: 500 }}>
                      {faq.description}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      px: 2,
                      py: 1.5,
                      borderTop: `1px solid ${theme.palette.divider}`,
                      backgroundColor: theme.palette.background.default,
                    }}
                  >
                    <Stack spacing={1.5}>
                      {' '}
                      <Typography variant='body2' sx={{ color: theme.palette.text.secondary }}>
                        {faq.detailed_description}
                      </Typography>
                      {faq.button_text && faq.button_url && (
                        <Box>
                          {' '}
                          <Button
                            variant='outlined'
                            size='small'
                            href={faq.button_url}
                            target={faq.button_new_tab ? '_blank' : '_self'}
                            rel={faq.button_new_tab ? 'noopener noreferrer' : undefined}
                            sx={{
                              color: theme.palette.tsw?.primary || theme.palette.primary.main,
                              borderColor: theme.palette.tsw?.tertiary || theme.palette.divider,
                              textTransform: 'none',
                              '&:hover': {
                                backgroundColor: theme.palette.action.hover,
                                borderColor:
                                  theme.palette.tsw?.primary || theme.palette.primary.main,
                              },
                            }}
                          >
                            {faq.button_text}
                          </Button>
                        </Box>
                      )}
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              ))
            )}
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default HelpModal;
