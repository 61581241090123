import React, { forwardRef, useState, useEffect, useMemo, useCallback } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  IconButton,
  Stack,
  CircularProgress,
  Alert,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@mui/material';
import { Delete, ExpandMore } from '@mui/icons-material';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import {
  deleteFromSmartOrderCart,
  updateCartItemQuantity,
} from '../../../../Redux/SmartOrderCartSlice';

const OrderConfirmModal = forwardRef(({ customerNumber, handleClose, handleOrderPlaced }, ref) => {
  const selectedRows = useSelector((state) => state.smartOrderCart.value);
  const dispatch = useDispatch();

  const [shippingOptions, setShippingOptions] = useState([]);
  const [selectedShippingOption, setSelectedShippingOption] = useState('');
  const [poNumber, setPoNumber] = useState('');
  const [shippingRules, setShippingRules] = useState(null);
  const [openPoWarning, setOpenPoWarning] = useState(false);
  const [isLoadingShipping, setIsLoadingShipping] = useState(true);
  const [shippingError, setShippingError] = useState('');
  const [token, setToken] = useState('');

  useEffect(() => {
    const path = window.location.pathname;

    const pathParts = path.split('/');

    const tokenFromUrl = pathParts[pathParts.length - 1];

    setToken(tokenFromUrl);
    setIsLoadingShipping(true);
    setShippingError('');
    const fetchShippingData = async () => {
      try {
        const [optionsResponse, rulesResponse] = await Promise.all([
          axios.get('/suggestedOrders/shippingOptions'),
          axios.get('/suggestedOrders/shippingRules'),
        ]);

        setShippingOptions(optionsResponse.data || []);
        if (optionsResponse.data && optionsResponse.data.length > 0) {
          setSelectedShippingOption(optionsResponse.data[0].shipping_method_id);
        }

        let rulesData = rulesResponse.data;
        if (Array.isArray(rulesData) && rulesData.length > 0 && rulesData[0].content) {
          rulesData = JSON.parse(rulesData[0].content);
        } else if (rulesData?.content && typeof rulesData.content === 'string') {
          rulesData = JSON.parse(rulesData.content);
        }

        setShippingRules(rulesData?.shipping_rules || {});
      } catch (error) {
        console.error('Error fetching shipping data:', error);
        setShippingError('Failed to load shipping information. Please try again later.');
      } finally {
        setIsLoadingShipping(false);
      }
    };

    fetchShippingData();
  }, []);

  const totalCost = useMemo(() => {
    return selectedRows.reduce(
      (total, row) => total + (parseFloat(row.cost) || 0) * row.suggested_qty,
      0
    );
  }, [selectedRows]);

  const formattedTotalCost = useMemo(() => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(totalCost);
  }, [totalCost]);

  const handleShippingChange = useCallback((event) => {
    setSelectedShippingOption(event.target.value);
  }, []);

  const handlePoNumberChange = useCallback((event) => {
    setPoNumber(event.target.value);
  }, []);

  const handleRemoveItem = useCallback(
    (itemNumber) => {
      dispatch(deleteFromSmartOrderCart(itemNumber));
    },
    [dispatch]
  );

  const handleClosePoWarning = useCallback(() => {
    setOpenPoWarning(false);
  }, []);

  const handleFinalConfirm = useCallback(async () => {
    const items = selectedRows.map((row) => ({
      item_number: row.item_number,
      quantity: row.suggested_qty,
      line_source: row.line_source,
    }));

    const selectedShippingMethodName = shippingOptions.find(
      (option) => option.shipping_method_id === selectedShippingOption
    )?.shipping_method;

    const orderData = {
      customerNumber: token,
      poNumber: poNumber.trim(),
      shippingMethod: selectedShippingMethodName,
      items: { items: items },
    };

    try {
      const response = await axios.post('/suggestedOrders/placeOrder', orderData);
      console.log('Order submitted successfully:', response.data);
      handleOrderPlaced();
      handleClose();
    } catch (error) {
      console.error('Error submitting order:', error);

      alert(`Error placing order: ${error.message || 'Unknown error'}`);
    } finally {
      if (openPoWarning) handleClosePoWarning();
    }
  }, [
    selectedRows,
    shippingOptions,
    selectedShippingOption,
    poNumber,
    handleOrderPlaced,
    handleClose,
    token,
    openPoWarning,
    handleClosePoWarning,
  ]);

  const handleConfirmClick = useCallback(() => {
    if (!poNumber.trim()) {
      setOpenPoWarning(true);
    } else {
      handleFinalConfirm();
    }
  }, [poNumber, handleFinalConfirm]);

  const renderShippingRules = () => {
    if (isLoadingShipping) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
          <CircularProgress size={24} />
          <Typography sx={{ ml: 1 }}>Loading Rules...</Typography>
        </Box>
      );
    }
    if (shippingError) {
      return (
        <Alert severity='error' sx={{ my: 2 }}>
          {shippingError}
        </Alert>
      );
    }
    if (!shippingRules || Object.keys(shippingRules).length === 0) {
      return <Typography sx={{ my: 2 }}>No shipping rules available.</Typography>;
    }

    const renderRuleList = (rules) => (
      <List dense disablePadding sx={{ pl: 2 }}>
        {rules.map((rule, idx) => (
          <ListItem key={idx} disableGutters sx={{ py: 0.2 }}>
            <ListItemText
              primary={`${rule.condition}: ${rule.shipping_cost}`}
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </ListItem>
        ))}
      </List>
    );

    return (
      <Box>
        {shippingRules.handguns && (
          <Box sx={{ mt: 1 }}>
            <Typography variant='subtitle2'>Handguns</Typography>
            <Typography variant='body2' color='text.secondary' gutterBottom>
              {shippingRules.handguns.note}
            </Typography>
            {renderRuleList(shippingRules.handguns.rules)}
          </Box>
        )}

        {shippingRules.other_products && (
          <Box sx={{ mt: 2 }}>
            <Typography variant='subtitle2'>Other Products</Typography>
            <Typography variant='body2' color='text.secondary' gutterBottom>
              {shippingRules.other_products.includes}
            </Typography>
            {renderRuleList(shippingRules.other_products.rules)}
          </Box>
        )}
      </Box>
    );
  };

  const handleQuantityChange = useCallback(
    (event, itemNumber) => {
      const rawValue = event.target.value;

      if (rawValue === '') {
        console.log(`Item ${itemNumber} quantity cleared, will be removed if left empty.`);
      }

      const newQuantity = parseInt(rawValue, 10);

      if (!isNaN(newQuantity)) {
        if (newQuantity >= 1) {
          dispatch(updateCartItemQuantity({ item_number: itemNumber, newQuantity }));
        } else {
          dispatch(deleteFromSmartOrderCart(itemNumber));
        }
      } else if (rawValue === '') {
        dispatch(deleteFromSmartOrderCart(itemNumber));
      } else {
        console.warn(`Invalid quantity input: ${rawValue}`);
      }
    },
    [dispatch]
  );

  return (
    <>
      <Dialog
        open={true}
        onClose={handleClose}
        ref={ref}
        maxWidth='md'
        fullWidth
        PaperProps={{
          sx: {
            maxHeight: '90vh',
          },
        }}
        aria-labelledby='order-confirm-dialog-title'
      >
        <DialogTitle id='order-confirm-dialog-title'>Confirm Your Order</DialogTitle>

        <DialogContent dividers>
          {' '}
          <Stack spacing={3}>
            {' '}
            <TableContainer component={Paper} variant='outlined'>
              {' '}
              <Table stickyHeader size='small' aria-label='order confirmation table'>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: '5%' }} />
                    <TableCell>Item #</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell align='right'>Cost</TableCell>
                    <TableCell align='right'>Quantity</TableCell>
                    <TableCell align='right'>Subtotal</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedRows.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={6} align='center'>
                        <Typography color='text.secondary' sx={{ py: 4 }}>
                          Your cart is empty.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    selectedRows.map((row) => (
                      <TableRow
                        key={row.item_number}
                        hover
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell padding='checkbox'>
                          <Tooltip title='Remove item' placement='right'>
                            <IconButton
                              size='small'
                              onClick={() => handleRemoveItem(row.item_number)}
                              aria-label={`Remove ${row.item_number}`}
                              color='error'
                            >
                              <Delete fontSize='small' />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          {row.item_number}
                        </TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell align='right'>
                          {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          }).format(parseFloat(row.cost) || 0)}
                        </TableCell>
                        <TableCell align='right'>
                          <TextField
                            variant='outlined'
                            type='number'
                            size='small'
                            value={row.suggested_qty}
                            onChange={(e) => handleQuantityChange(e, row.item_number)}
                            inputProps={{
                              min: 0,
                              style: { textAlign: 'right', width: '60px' },
                            }}
                            sx={{ width: '80px' }}
                          />
                        </TableCell>
                        <TableCell align='right'>
                          {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          }).format((parseFloat(row.cost) || 0) * row.suggested_qty)}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Accordion variant='outlined'>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls='shipping-rules-content'
                id='shipping-rules-header'
              >
                <Typography variant='h6' component='div'>
                  {shippingRules?.title || 'Shipping Rules'}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ pt: 0 }}> {renderShippingRules()}</AccordionDetails>
            </Accordion>
            <Paper variant='outlined' sx={{ p: 2 }}>
              <Stack spacing={2}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <FormControl fullWidth disabled={isLoadingShipping}>
                    <InputLabel id='shipping-method-label'>Shipping Method</InputLabel>
                    <Select
                      labelId='shipping-method-label'
                      value={selectedShippingOption}
                      onChange={handleShippingChange}
                      label='Shipping Method'
                      required
                    >
                      {isLoadingShipping && (
                        <MenuItem value='' disabled>
                          <em>Loading...</em>
                        </MenuItem>
                      )}
                      {shippingOptions.map((option) => (
                        <MenuItem key={option.shipping_method_id} value={option.shipping_method_id}>
                          {option.shipping_method}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <TextField
                    fullWidth
                    label='PO Number (Optional)'
                    variant='outlined'
                    value={poNumber}
                    onChange={handlePoNumberChange}
                  />
                </Stack>
                <Divider sx={{ my: 1 }} />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  <Typography variant='h6' component='span' sx={{ fontWeight: 'bold' }}>
                    Order Total: {formattedTotalCost}
                  </Typography>
                </Box>
              </Stack>
            </Paper>
          </Stack>
        </DialogContent>

        <DialogActions sx={{ p: 2 }}>
          {' '}
          <Button variant='text' color='secondary' onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={handleConfirmClick}
            disabled={isLoadingShipping || selectedRows.length === 0 || !selectedShippingOption}
          >
            Confirm Order
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openPoWarning}
        onClose={handleClosePoWarning}
        aria-labelledby='po-warning-dialog-title'
      >
        <DialogTitle id='po-warning-dialog-title'>Missing PO Number</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have not entered a PO Number. Do you want to proceed without it?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePoWarning} color='secondary'>
            Cancel
          </Button>
          <Button onClick={handleFinalConfirm} color='primary' autoFocus>
            Confirm Order
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default OrderConfirmModal;
