import * as React from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  CssBaseline,
  AppBar as MuiAppBar,
  Toolbar,
  Grid,
  IconButton,
  Typography,
  Drawer,
  List,
  Divider,
  useMediaQuery,
  Menu,
  MenuItem,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import ManageAccounts from '@mui/icons-material/ManageAccounts';
import Settings from '@mui/icons-material/Settings';
import GroupAdd from '@mui/icons-material/GroupAdd';

import { useSelector, useDispatch } from 'react-redux';
import { setMainDrawer } from '../../Redux/MainDrawerSlice';
import { resetUserInformation } from '../../Redux/UserInformationSlice';
import { resetUserPermission } from '../../Redux/UserPermissionSlice';
import PageTitle from '../_GlobalFunctions/PageTitle';
import DrawerMenu from './Components/DrawerMenu';
import LogoName from '../../Assets/logo.png';
import LogoDuck from '../../Assets/ssDuckSquare.png';

import { useState } from 'react';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    paddingTop: `${theme.spacing(8)}`,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
    display: 'flex',
    flexDirection: 'column',
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

export default function PersistentDrawerLeft() {
  const location = useLocation();
  const currentPathname = location.pathname;
  const locationDataAudit = currentPathname.includes('/product-audit');
  const locationBrandLanding = currentPathname.includes('/brand-landing-page');

  const isScreenSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const theme = useTheme();
  const drawerState = useSelector((state) => state.mainDrawer.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInformation = useSelector((state) => state.userInformation.value);
  const userLastName = userInformation.lastName;
  const userFirstName = userInformation.firstName;

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
    dispatch(resetUserInformation());
    dispatch(resetUserPermission());
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position='fixed'
        open={drawerState}
        sx={{ boxShadow: locationDataAudit ? 'none' : null }}
      >
        <Toolbar>
          <Grid container alignItems='center' justifyContent='space-between'>
            {/* Left Section: Menu and Logo */}
            <Grid item xs={3} container alignItems='center'>
              <IconButton
                color='inherit'
                aria-label='open drawer'
                onClick={() => dispatch(setMainDrawer(true))}
                edge='start'
                sx={{ mr: 2, ...(drawerState && { display: 'none' }) }}
              >
                <MenuIcon />
              </IconButton>
              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <img
                  src={LogoDuck}
                  alt='logo'
                  style={{ maxWidth: 90, height: 'auto', marginBottom: 5 }}
                />
              </Box>
            </Grid>

            {/* Center Section: Page Title */}
            <Grid item xs={6} container justifyContent='center'>
              {isScreenSmall ? (
                <Typography variant='h6' noWrap component='div'>
                  {PageTitle()}
                </Typography>
              ) : (
                <Typography variant='h4' noWrap component='div'>
                  {PageTitle()}
                </Typography>
              )}
            </Grid>

            {/* Right Section: User Info and Menu */}
            <Grid item xs={3} container alignItems='center' justifyContent='flex-end'>
              {isScreenSmall ? (
                <Typography variant='h6' noWrap component='div'>
                  {userFirstName.charAt(0)}
                  {userLastName.charAt(0)}
                </Typography>
              ) : (
                <Typography variant='h5' noWrap component='div'>
                  {`${userFirstName} ${userLastName}`}
                </Typography>
              )}
              <ExpandMoreIcon
                onClick={handleClick}
                size='small'
                sx={{ ml: { xs: 0, sm: 2 } }}
                aria-controls={openMenu ? 'account-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={openMenu ? 'true' : undefined}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      {/* Drawer Component */}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box' },
        }}
        variant='persistent'
        anchor='left'
        open={drawerState}
      >
        <DrawerHeader>
          <img src={LogoName} alt='logo' style={{ maxHeight: 25 }} />
          <IconButton onClick={() => dispatch(setMainDrawer(false))}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon style={{ color: 'white' }} />
            ) : (
              <ChevronRightIcon style={{ color: 'white' }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <DrawerMenu />
        </List>
      </Drawer>

      {/* Main Content */}
      <Main
        open={drawerState}
        sx={{
          backgroundColor: locationDataAudit ? '#f3f5f6' : null,
          width: '100%',
        }}
      >
        <Box
          className='outlet-wrapper'
          sx={{
            flexGrow: 1,
            overflowY: 'auto',
            py: 1,
            px: 0,
          }}
        >
          <Outlet />
        </Box>
      </Main>

      {/* Account Menu */}
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={openMenu}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': { width: 32, height: 32, ml: -0.5, mr: 1 },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={(event) => event.stopPropagation()} disableRipple>
          <Typography
            variant='h6'
            noWrap
            component='div'
            sx={{ color: 'text.primary', marginBottom: '0.25em' }}
          >
            {userInformation.businessName}
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => navigate('/account-settings')}>
          <ListItemIcon>
            <Settings fontSize='small' />
          </ListItemIcon>
          Account Settings
        </MenuItem>
        <MenuItem onClick={() => navigate('/manage-users')}>
          <ListItemIcon>
            <GroupAdd fontSize='small' />
          </ListItemIcon>
          Manage Users
        </MenuItem>
        <MenuItem onClick={() => navigate('/user-preferences')}>
          <ListItemIcon>
            <ManageAccounts fontSize='small' />
          </ListItemIcon>
          User Preferences
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize='small' />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
}
